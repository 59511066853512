.lazy-loader-container{
    width:100%;
    height:200px;
    margin-top: 50px;
}

.lazy-loader {
    content: '';
    position: relative;
    width: 50px;
    height: 50px;
    margin: auto;
    -webkit-animation: loader-rotation .8s infinite linear;
    animation: loader-rotation .8s infinite linear;
    border-left: 3px solid rgba(114, 144, 182, 0.15);
    border-right: 3px solid rgba(114, 144, 182, 0.15);
    border-bottom: 3px solid rgba(114, 144, 182, 0.15);
    border-top: 3px solid rgba(114, 144, 182, 0.8);
    border-radius: 100%;
}

@-webkit-keyframes loader-rotation {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes loader-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
