@import "Styles/stats-popup";
@import "Styles/spinner";
@import "Styles/dashboard.scss";
@import "Styles/buttons";

.App {
    text-align: center;
    background-color: #F9F9F9;
    color: black;
}

.page-content {
    background-color: #F9F9F9;
    padding-top: 55px;
    min-height: calc(100vh - 30px);
}

.list-items-stats{
    list-style: none;
    padding-left: 0 !important;
}

.footer {
    height: 50px;
}
