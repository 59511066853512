$delay: 100ms;
$function: 200ms;
$duration: 400ms;

.hit-box-container {
  position: relative;
  display: inline-block;
  flex-direction: column;
  width: auto;
  height: auto;
  margin: 0 5px;
}

.hitbox {
  width: 100%;
  height: 100%;
  display: block;
}

.info-box {
  display: flex;
  position: absolute !important;
  left: 50%;
  opacity: 0;
  top: 0;
  transform: translate(-50%, -40px);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 200px;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.hitbox.stats:hover + .info-box.stats {
  opacity: 1;
  z-index: 100;
}

.hitbox.redo:hover + .info-box.redo {
  opacity: 1;
  z-index: 100;
}

.hitbox.skip:hover + .info-box.skip {
  opacity: 1;
  z-index: 100;
}

.hitbox.download:hover + .info-box.download {
  opacity: 1;
  z-index: 100;
}


.hitbox.uploadError:hover + .info-box.uploadError {
  opacity: 1;
  z-index: 100;
}

.hitbox.configurationError:hover + .info-box.configurationError {
  opacity: 1;
  z-index: 100;
}

.action-button {
  border-radius: 3px;
  min-width: 60px;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 10px 16px;
  transition: 200ms ease-in-out all;
  margin: 0 5px;
  text-align: center;

  svg {
    path {
      fill: white;
    }
  }

  &:after {
    opacity: 0;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.2);
    transition: 300ms ease-in-out all;
  }

  &.regular {
    background-color: #E0E1E2;

    svg {
      path {
        fill: #5b5b5b;
      }
    }
  }

  &.default {
    background-color: #417ce6;
  }

  &.negative {
    background-color: #ff3300;
  }

  &.positive {
    background-color: #00dc00;
  }

  &.caution {
    background-color: #ffa300;
  }

  &.disabled {
    opacity: 0.4 !important;

    &:hover {
      &:after {
        opacity: 0 !important;
      }

      cursor: not-allowed !important;

      .popup {
        opacity: 0 !important;
      }
    }
  }

  .popup {
    opacity: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    width: 100px;
    height: 50px;
    background-color: #F9FAFB;
    z-index: 100;
    transition: 300ms ease-in-out all;
    position: absolute;
    border: 1px solid #1a1a1a;
    top: -120%;
    left: 50%;
    transform: translateX(-50%);

    p {
      text-align: center;
      color: #1a1a1a !important;
    }
  }

  &:hover {
    &:after {
      opacity: 1;
      transition: 300ms ease-in-out all;
    }

    cursor: pointer;

    .popup {
      opacity: 1;
      transition: 300ms ease-in-out all;
    }
  }
}

//Graph loader
.graph-loader-container {
  height: 20px;
  width: 100%;
  position: relative;
  display: inline-block;
}

.loader-7 .line {
  width: 3px;
  position: absolute;
  border-radius: 5px;
  bottom: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#5b5b5b), to(#5b5b5b));
  background: -webkit-linear-gradient(top, #5b5b5b, #5b5b5b);
  background: -o-linear-gradient(top, #5b5b5b, #5b5b5b);
  background: linear-gradient(to bottom, #5b5b5b, #5b5b5b);
}

.loader-7 .line1 {
  left: 0;
  -webkit-animation: line-grow $duration ease alternate infinite;
  animation: line-grow $duration ease alternate infinite;
}

.loader-7 .line2 {
  left: 10px;
  -webkit-animation: line-grow $duration 0.2s ease alternate infinite;
  animation: line-grow $duration 0.2s ease alternate infinite;
}

.loader-7 .line3 {
  left: 20px;
  -webkit-animation: line-grow $duration 0.4s ease alternate infinite;
  animation: line-grow $duration 0.4s ease alternate infinite;
}


@keyframes line-grow {
  0% {
    height: 0;
  }
  100% {
    height: 75%;
  }
}