.ui.message {
    padding:10px!important;
    margin:0 !important;
    list-style-position: inside !important;
    text-align: left !important;

    ul{
        padding:0 !important;

    }
}

.ui.table td.negative, .ui.table tr.negative.heavy-error{
    background-color: #ffdadc !important;
    color:#9f3a38;
}

.ui.table td.negative, .ui.table tr.negative.heavy-error:hover{
    background-color: #FFBEC1 !important;
    color:#9f3a38;
}

.ui.negative.message.heavy-error {
    background-color: #ffdadc !important;
    color:#9f3a38;
    ul{
        padding:0 !important;
        list-style-position: inside;
        text-align: left;
    }
}

/*//Remove margin from info icon*/
.ui.button:not(.icon)>.icon:not(.button):not(.dropdown){
    margin: 0;
}

.centered-button{
    display: flex;
    justify-content: center;
    width:auto;
}

